<template>
  <section>
    <b-row class="file mt-3">
      <b-col cols="9" @click="openDocumentFile">
        <p class="name">
          {{ file[0].group_name || file[0].custom_name }}
        </p>
        <p class="info">
          {{ moment(file[0].created_at).format('DD [de] MMMM [de] YYYY') }}
        </p>
      </b-col>
      <b-col cols="3">
        <slot />
      </b-col>
      <div class="divider" />
    </b-row>
  </section>
</template>

<script>
export default {
  name: 'FileList',
  props: {
    file: {
      type: Array,
      required: true
    }
  },
  methods: {
    openDocumentFile() {
      this.$emit('openDocumentFile', this.file)
    },
  }
}
</script>

<style lang="scss" scoped>
.file {
  cursor: pointer;
  .name {
    font-size: min(1.2vw, 16px);
    font-weight: 600;
    color: var(--type-active);
  }

  .info {
    font-weight: 600;
    font-size: 12px;
    color: var(--type-placeholder);
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--neutral-200);
    margin: 10px 0;
  }
}
</style>
